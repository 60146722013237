<template>
  <section class="search-flyout">
    <div
      id="search-suggestions"
      class="search-flyout__container"
      :class="{ 'search-flyout__multi-col': searchModeSuggestions !== SearchMode.all }"
      role="listbox"
      :aria-label="
        $t('search.results.label', {
          searchTerm: searchTermSuggestions,
        })
      "
    >
      <div
        v-if="products.length && searchModeSuggestions !== SearchMode.topics"
        class="search-flyout__products"
        data-tosca="search-flyout-products"
      >
        <div id="products-title" class="search-flyout__results-title">
          {{ $t("search.results.products.title") }}
        </div>
        <ul class="search-flyout__results">
          <li v-for="product in products" :key="product.productId" class="search-flyout__result">
            <spar-link
              class="search-flyout__result-info"
              :link="product.shopUrl"
              :title="product.name2"
              aria-describedby="products-title"
              @click="showSearchOverlay = false"
            >
              <spar-adaptive-image
                v-if="product?.tilePicture && product.tilePicture.servletBaseUrl"
                class="search-flyout__result-img"
                :src="productImgSrc(product.tilePicture?.servletBaseUrl)"
              />
              <div>
                <div v-if="product.name1" class="search-flyout__result-heading">
                  {{ product.name1 }}
                </div>
                <div v-if="product.name2" class="search-flyout__result-description">
                  {{ useCropText(product.name2, 50) }}
                </div>
              </div>
            </spar-link>
            <div class="search-flyout__result-link">
              <spar-link
                icon="arrow-right"
                icon-only
                :link="product.shopUrl"
                :title="product.name2"
                :variant="LinkVariant.linkIcon"
                :aria-label="
                  $t('search.results.products.link.label', {
                    productTitle: `${product.name1} ${useCropText(product.name2 || '', 50)}`,
                  })
                "
                aria-describedby="products-title"
                class="btn btn--primary"
                @click="showSearchOverlay = false"
              />
            </div>
          </li>
        </ul>
        <div class="search-flyout__results-link">
          <spar-link
            :link="getPath('search')"
            icon="arrow-right"
            icon-position="right"
            :variant="LinkVariant.secondaryIcon"
            @click.prevent="showMore(SearchMode.products)"
          >
            {{ $t("search.results.products.link") }}
          </spar-link>
        </div>
      </div>
      <div
        v-else-if="
          !products.length && searchModeSuggestions === SearchMode.products && !isLoadingSuggestions
        "
        aria-live="polite"
        role="alert"
      >
        {{ $t("search.groups.products.no_results") }}
      </div>

      <div
        v-if="topics.length && searchModeSuggestions !== SearchMode.products"
        class="search-flyout__topics"
        data-tosca="search-flyout-topics"
      >
        <div id="topics-title" class="search-flyout__results-title">
          {{ $t("search.results.topics.title") }}
        </div>
        <ul class="search-flyout__results">
          <li v-for="suggestion in topics" :key="suggestion.id" class="search-flyout__result">
            <div>
              <div class="search-flyout__result-heading">
                <strong>{{ suggestion.title }}</strong>
              </div>
              <div class="search-flyout__result-description">
                {{ useCropText(suggestion.description, 200) }}
              </div>
            </div>
            <div class="search-flyout__result-link">
              <spar-link
                icon="arrow-right"
                icon-only
                :variant="LinkVariant.linkIcon"
                :aria-label="
                  $t('search.results.topics.link.label', {
                    topicTitle: suggestion.title,
                  })
                "
                aria-describedby="topics-title"
                class="btn btn--primary"
                :link="suggestion.slug"
                @click="showSearchOverlay = false"
              />
            </div>
          </li>
        </ul>
        <div class="search-flyout__results-link">
          <spar-link
            :link="getPath('search')"
            icon="arrow-right"
            icon-position="right"
            :variant="LinkVariant.secondaryIcon"
            @click.prevent="showMore(SearchMode.topics)"
          >
            {{ $t("search.results.topics.link") }}
          </spar-link>
        </div>
      </div>
      <div
        v-else-if="
          !topics.length &&
          searchModeSuggestions === SearchMode.topics &&
          !isLoadingSuggestions &&
          searchTermSuggestions
        "
        aria-live="polite"
        role="alert"
      >
        {{ $t("search.groups.topics.no_results") }}
      </div>

      <div
        v-if="
          !products.length &&
          !topics.length &&
          searchModeSuggestions === SearchMode.all &&
          !isLoadingSuggestions &&
          searchTermSuggestions
        "
        aria-live="polite"
        role="alert"
      >
        {{ $t("search.groups.no_results") }}
      </div>

      <div
        v-if="(products.length || topics.length) && !isLoadingSuggestions"
        aria-live="polite"
        role="alert"
        class="visually-hidden"
      >
        <span v-if="!topics.length">
          {{
            $t("search.suggestions.products.found", {
              products: products.length.toString(),
            })
          }}
        </span>
        <span v-else-if="!products.length">
          {{
            $t("search.suggestions.topics.found", {
              topics: topics.length.toString(),
            })
          }}
        </span>
        <span v-else>
          {{
            $t("search.suggestions.found", {
              products: products.length.toString(),
              topics: topics.length.toString(),
            })
          }}
        </span>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { SearchMode } from "~/components/sections/SparHeader/SparSearchField/SparSearchField.types";
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import { LinkVariant } from "~/components/shared/SparLink/SparLink.types";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import { useCropText } from "~/composables/utils/useCropText";
import { useSearchStore } from "~/stores/search.store";

const { $t } = useI18n();
const { getPath } = useRoutes();
const { triggerSearchFromFlyout } = useSearchStore();
const {
  topicSuggestions,
  productSuggestions,
  searchModeSuggestions,
  showSearchOverlay,
  isLoadingSuggestions,
  searchTermSuggestions,
} = storeToRefs(useSearchStore());

const topics = computed(() => {
  const resultsCount = searchModeSuggestions.value === SearchMode.topics ? 6 : 3;
  return topicSuggestions.value.slice(0, resultsCount);
});

const products = computed(() => {
  const resultsCount = searchModeSuggestions.value === SearchMode.products ? 8 : 5;
  return productSuggestions.value.slice(0, resultsCount);
});

const productImgSrc = (src: string) => {
  return src.replace("{size}", "50").replace("{ext}", "jpg");
};

const showMore = (mode: SearchMode) => {
  triggerSearchFromFlyout(mode);
};
</script>

<style lang="scss">
@use "./SparSearchFlyout.scss";
</style>
