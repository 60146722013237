<template>
  <div
    v-if="
      (subNavItem.childCategories && subNavItem.childCategories.length) ||
      (subNavItem.components && subNavItem.components.length)
    "
    class="navigation-flyout"
    :class="{
      'navigation-flyout--active': checkActive(subNavItem, 3),
      'navigation-flyout--not-openable': !flyoutOpenable,
    }"
  >
    <div class="navigation-flyout__close-link-wrapper">
      <spar-button
        class="navigation-flyout__close-link btn--clear"
        type="button"
        :aria-label="subNavItem.label"
        icon="arrow-left"
        :icon-position="IconPosition.left"
        :variant="ButtonVariant.custom"
        :tosca-prefix="`header-nav-flyout-${subNavItem.label?.toLocaleLowerCase()}`"
        @click="closeFlyout(subNavItem)"
      >
        {{ subNavItem.label }}
      </spar-button>
    </div>
    <div class="navigation-flyout__content">
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events, vuejs-accessibility/no-static-element-interactions -->
      <div class="navigation-flyout__back-drop" @click="closeFlyout(subNavItem)"></div>

      <div
        v-if="subNavItem.childCategories && subNavItem.childCategories.length"
        class="navigation-flyout__column navigation-flyout__nav-wrapper"
      >
        <ul
          class="navigation-flyout__nav"
          :class="{
            'navigation-flyout__nav--columns':
              subNavItem.childCategories && subNavItem.childCategories.length >= 8,
          }"
          role="menu"
        >
          <li
            v-for="flyoutNav in subNavItem.childCategories"
            :key="flyoutNav.categoryId"
            role="none"
            class="navigation-flyout__nav-link-wrapper"
            @click="closeFlyout(subNavItem)"
          >
            <spar-link
              class="navigation-flyout__nav-link"
              role="menuitem"
              :label="flyoutNav.label"
              :link="flyoutNav.link"
              @click="setActiveMenu(flyoutNav)"
            >
              <span
                class="navigation-flyout__nav-link-content"
                :data-tosca="`header-navflyout-${flyoutNav.label}`"
              >
                <span>{{ flyoutNav.label }}</span>

                <spar-icon-sprite
                  v-if="flyoutNav.categoryIconUrl"
                  class="navigation-flyout__nav-link-icon"
                  :symbol="flyoutNav.categoryIconUrl"
                ></spar-icon-sprite>
              </span>
            </spar-link>
          </li>
        </ul>
      </div>

      <div
        v-if="subNavItem.components && subNavItem.components.length"
        class="navigation-flyout__column"
      >
        <ul class="navigation-flyout__components" role="menu">
          <li
            v-for="component in subNavItem.components"
            :key="component.categoryId"
            role="none"
            class="navigation-flyout__component-wrapper"
          >
            <div class="navigation-flyout__component-inner">
              <spar-link
                class="navigation-flyout__component-link f-bold"
                role="menuitem"
                :label="component.label"
                :link="component.link"
              >
                <spar-adaptive-image
                  v-if="component.img"
                  class="navigation-flyout__image"
                  :src="component.img.src"
                  :alt="component.img.alt"
                />
              </spar-link>
            </div>
          </li>
        </ul>
        <div class="navigation-flyout__all-link-wrapper" data-tosca="nav3-flyout-wrapper">
          <spar-link
            class="navigation-flyout__all-link btn btn--primary"
            link="#"
            label="T: Alle Inspirationswelten"
          ></spar-link>
        </div>
      </div>

      <div class="navigation-flyout__sections">
        <render-sections :content="subNavItem.sections" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import type { NavigationNode } from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import { useNavigationStore } from "~/stores/navigation.store";
import { IconPosition } from "~/types/icon.types";

defineProps({
  subNavItem: {
    type: Object as PropType<NavigationNode>,
    required: true,
  },
  flyoutOpenable: {
    type: Boolean,
  },
  levelPath: {
    type: Array as PropType<string[]>,
    required: true,
  },
  activeLevel: {
    type: Number,
    default: 0,
  },
});

const { closeFlyout, checkActive, setActiveMenu } = useNavigationStore();
</script>

<style lang="scss">
@use "./SparNavigationFlyout.scss";
</style>
