<template>
  <div class="location-actions__actions">
    <spar-button
      v-if="showDetailLink"
      class="location-overlay__store-select"
      :variant="ButtonVariant.secondary"
      tosca-prefix="location-overlay-show-details"
    >
      {{ $t("location.map.show_details") }}
    </spar-button>

    <spar-button
      v-if="isFavourite"
      class="location-actions__store-select"
      :variant="ButtonVariant.light"
      :aria-label="`${$t('location.map.favourite_store.select')}`"
      tosca-prefix="location-overlay-select-favourite-store"
      icon="heart"
      icon-position="left"
      @click="emit('setFavouriteStoreHandler', store)"
    >
      {{ $t("location.map.favourite_store") }}
    </spar-button>

    <spar-button
      v-else
      class="location-actions__store-select"
      :aria-label="`${$t('location.map.favourite_store.delete')}`"
      :variant="ButtonVariant.light"
      tosca-prefix="location-overlay-delete-favourite-store"
      icon="heart-filled"
      icon-position="left"
      @click="emit('setFavouriteStoreHandler', undefined)"
    >
      {{ $t("location.map.favourite_store") }}
    </spar-button>
  </div>
</template>

<script setup lang="ts">
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import type { SapStore } from "~/utils/sapcc/stores/types";

const { $t } = useI18n();

const emit = defineEmits(["setFavouriteStoreHandler"]);

defineProps({
  isFavourite: {
    type: Boolean,
    default: false,
  },
  showDetailLink: {
    type: Boolean,
    default: true,
  },
  store: {
    type: Object as PropType<SapStore>,
    required: true,
  },
});
</script>

<style lang="scss">
@use "./SparLocationActions.scss";
</style>
