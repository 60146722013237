<template>
  <div class="opening-hours" :class="{ 'opening-hours--open': currentOpening.willCloseAt }">
    <spar-heading
      class="opening-hours__store-headline"
      level="4"
      headline-style="4"
      :title="title"
      additional-class="heading--left"
    />

    <div class="opening-hours__group">
      <div class="opening-hours__open-headline">
        {{ currentOpening.willCloseAt ? $t("location.openNow") : $t("location.closed") }}
      </div>
      <div class="opening-hours__time">
        <div v-if="currentOpening.willCloseAt" class="opnening-hours__will-close">
          {{
            $t("location.will_close", {
              time: currentOpening.willCloseAt.formattedHour,
            })
          }}
        </div>

        <div v-else>
          {{
            $t(
              currentOpening.willOpenAtDayCount && currentOpening.willOpenAtDayCount < 2
                ? "location.will_open_tomorrow"
                : "location.will_open",
              {
                time: currentOpening.opensAt
                  ? useDateFormat(currentOpening.opensAt, "HH:mm").value
                  : "",
                day:
                  currentOpening.willOpenAtDayCount === 0
                    ? ""
                    : useDateFormat(props.currentOpening.opensAt, "dddd").value,
              },
            )
          }}
        </div>
      </div>
    </div>

    <spar-accordion
      :content-id="`opening-hours__content-${openingHours.code}`"
      :label-id="`opening-hours__label-${openingHours.code}`"
      :level="2"
      :label="showMoreLabel"
      styling="reduced"
      tosca-prefix="opening-hours"
      @open="showOpeningHours"
    >
      <template #content>
        <div
          v-for="day in sortedWeekdays"
          :key="day.weekDay"
          class="location-ovleray__opening-hours"
        >
          <div class="opening-hours__group">
            <div class="opening-hours__date">
              {{ day.weekDay }}
            </div>
            <div class="opening-hours__time">
              {{ day.openingTime?.formattedHour }} -
              {{ day.closingTime?.formattedHour }}
            </div>
          </div>
        </div>
      </template>
    </spar-accordion>
  </div>
</template>

<script lang="ts" setup>
import { sortByCurrentDay } from "~/components/feature/SparLocation/SparLocationOverlay/SparLocationOverlay.utils";
import SparAccordion from "~/components/shared/SparAccordion/SparAccordion.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import type { CurrentOpening, OpeningHours, WeekDayOpening } from "~/utils/sapcc/stores/types";

const { $t } = useI18n();

const sortedWeekdays = ref<WeekDayOpening[]>();

const props = defineProps({
  openingHours: {
    type: Object as PropType<OpeningHours>,
    required: true,
  },
  currentOpening: {
    type: Object as PropType<CurrentOpening>,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  showMoreLabel: {
    type: String,
    default: "",
  },
});

const showOpeningHours = () => {
  sortedWeekdays.value = sortByCurrentDay(props.openingHours.weekDayOpeningList);
};
</script>

<style lang="scss">
@use "./SparOpeningHours.scss";
</style>
