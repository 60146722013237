<template>
  <div class="layout--default content">
    <spar-header v-if="pageHeader" v-bind="pageHeader" />
    <slot />
    <spar-footer v-if="pageFooter" v-bind="pageFooter" />
    <spar-notification />
    <spar-back-to-top />

    <!-- Overlays here are used in multiple places -->
    <spar-overlay
      v-if="showMapOverlay && selectedProduct"
      class="location-overlay"
      :title="$t('location.map.title')"
      :is-active="showMapOverlay"
      @close="showMapOverlay = false"
    >
      <spar-location-overlay :product="selectedProduct" />
    </spar-overlay>
  </div>
</template>

<script lang="ts" setup>
import SparLocationOverlay from "~/components/feature/SparLocation/SparLocationOverlay/SparLocationOverlay.vue";
import SparFooter from "~/components/sections/SparFooter/SparFooter.vue";
import SparHeader from "~/components/sections/SparHeader/SparHeader.vue";
import SparBackToTop from "~/components/shared/SparBackToTop/SparBackToTop.vue";
import SparNotification from "~/components/shared/SparNotification/SparNotification.vue";
import SparOverlay from "~/components/shared/SparOverlay/SparOverlay.vue";
import { useLayout } from "~/composables/layout/useLayout";

const { getPageFooter, getPageHeader } = useLayout();
const { showMapOverlay, selectedProduct } = storeToRefs(useLocationStore());

const pageFooter = await getPageFooter();
const pageHeader = await getPageHeader();
</script>

<style lang="scss">
@use "./default.scss";
</style>
