<template>
  <div ref="subNavigationObserver" class="sub-navigation__wrapper">
    <div class="sub-navigation" :data-tosca="`header-nav-row2`">
      <ul class="sub-navigation__list" role="menu">
        <li
          v-for="(subNavItem, index) in subNavigation"
          :key="subNavItem.categoryId"
          role="none"
          class="sub-navigation__item"
          :class="checkActive(subNavItem, 2) ? 'sub-navigation__item--active' : ''"
          :data-tosca="`header-nav-row2-${index}`"
        >
          <spar-button
            v-if="subNavItem.childCategories && subNavItem.childCategories.length"
            class="sub-navigation__item-link btn--clear f-bold f-size--text-1"
            :aria-label="subNavItem.label"
            :data-label="subNavItem.label"
            :variant="ButtonVariant.custom"
            @click="setActiveMenu(subNavItem)"
          >
            {{ subNavItem.label }}
            <span class="sub-navigation__item-link-icon">
              <spar-icon-sprite symbol="arrow-right" />
            </span>
          </spar-button>
          <spar-link
            v-else
            class="sub-navigation__item-link f-bold f-size--text-1"
            role="menuitem"
            :label="subNavItem.label"
            :link="subNavItem.link"
            :data-label="subNavItem.label"
            @click="setActiveMenu(subNavItem)"
          />

          <spar-navigation-flyout
            :data-tosca="`navigation-level3-flyout-${index}`"
            :sub-nav-item="subNavItem"
            :level-path="levelPath"
            :active-level="activeLevel"
            :flyout-openable="flyoutOpenable"
          />
        </li>
      </ul>

      <spar-meta-nav
        v-if="showMobileMetaNavigation"
        class="sub-navigation__mobile-meta-nav"
        v-bind="metaNav"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useResizeObserver } from "@vueuse/core";
import type { NavigationNode } from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";
import SparNavigationFlyout from "~/components/sections/SparHeader/SparMainNavigation/SparNavigationFlyout/SparNavigationFlyout.vue";
import type { SparMetaNavProps } from "~/components/sections/SparHeader/SparMetaNav/SparMetaNav.types";
import SparMetaNav from "~/components/sections/SparHeader/SparMetaNav/SparMetaNav.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import { useNavigationStore } from "~/stores/navigation.store";

const { setActiveMenu, checkActive } = useNavigationStore();
const { subNavigationHeight } = storeToRefs(useNavigationStore());

const subNavigationObserver = ref(null);

useResizeObserver(subNavigationObserver, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;
  if (height) {
    subNavigationHeight.value = height;
  }
});

defineProps({
  subNavigation: {
    type: Array as PropType<NavigationNode[]>,
    required: true,
  },
  parent: {
    type: Object as PropType<NavigationNode>,
    required: true,
  },
  flyoutOpenable: {
    type: Boolean,
  },
  showMobileMetaNavigation: {
    type: Boolean,
    default: false,
  },
  levelPath: {
    type: Array as PropType<string[]>,
    required: true,
  },
  activeLevel: {
    type: Number,
    default: 0,
  },
  metaNav: {
    type: Object as PropType<SparMetaNavProps>,
    required: true,
  },
});
</script>

<style lang="scss">
@use "./SparSubNavigation.scss";
</style>
