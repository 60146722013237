<template>
  <div class="main-header" :style="`margin-bottom: ${marginBottom}px`">
    <div class="main-header__inner">
      <spar-main-navigation :aria-label="mainNavigation.ariaLabel" :meta-nav="metaNav" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { SparMainNavigationProps } from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";
import SparMainNavigation from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.vue";
import { useNavigationStore } from "~/stores/navigation.store";
import type { SparMetaNavProps } from "../SparMetaNav/SparMetaNav.types";
import type { SparMainHeaderProps } from "./SparMainHeader.types";

const { subNavigationHeight } = storeToRefs(useNavigationStore());
const { isMobile } = useScreenInfo();

const marginBottom = ref(0);

const setMarginBottom = () => {
  if (process.client) {
    marginBottom.value = !isMobile.value ? subNavigationHeight.value + 30 : 0;
  }
};

watch([isMobile, subNavigationHeight], () => {
  setMarginBottom();
});

onMounted(() => {
  setMarginBottom();
});

defineProps({
  mainNavigation: {
    type: Object as PropType<SparMainNavigationProps>,
    required: true,
  },
  metaNav: {
    type: Object as PropType<SparMetaNavProps>,
    required: true,
  },
}) as SparMainHeaderProps;
</script>

<style lang="scss">
@use "./SparMainHeader.scss";
</style>
