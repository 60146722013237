<template>
  <div ref="search">
    <div class="search-field__wrapper">
      <div class="search-field">
        <form class="search-field__form" role="search" @submit.prevent="onSubmit">
          <div class="search-field__select" data-tosca="header-search-select">
            <spar-select
              v-model="searchModeSuggestions"
              class="search-field__options"
              :aria-label="$t('header.search.options.label')"
              tosca-prefix="header-search"
              :options="options"
              show-hint
              data-tosca="header-search-select"
              @change="handleSuggestions"
            />
          </div>

          <div class="search-field__action">
            <input
              id="search"
              v-model="searchTermSuggestions"
              data-tosca="header-search-field-input"
              :placeholder="placeholder"
              :aria-label="placeholder"
              :aria-expanded="showSearchOverlay"
              aria-controls="search-suggestions"
              aria-haspopup="listbox"
              role="searchbox"
              maxlength="100"
              minlength="1"
              name="search"
              type="text"
              class="search-field__input spar-input__input"
              @focusin="showSearchOverlay = true"
              @input="handleSearchInput"
            />
            <spar-button
              class="search-field__submit-btn btn--clear"
              type="submit"
              icon="search"
              icon-only
              tosca-prefix="header-search-submit"
              :variant="ButtonVariant.custom"
              :aria-label="$t('header.search.submit')"
            />
          </div>
        </form>
      </div>

      <div class="search-field__close-wrapper">
        <spar-button
          :variant="ButtonVariant.custom"
          class="btn--secondary"
          :aria-label="$t('header.search.close')"
          icon="close"
          icon-only
          tosca-prefix="header-search-close"
          @click="
            setMobileMenuActive(null, true);
            showSearchOverlay = false;
          "
        ></spar-button>
      </div>
    </div>

    <div v-if="showSearchOverlay">
      <div class="search-field__flyout-search container"><spar-search-flyout /></div>
      <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events, vuejs-accessibility/no-static-element-interactions -->
      <div class="search-field__flyout-search-backdrop" @click="showSearchOverlay = false"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn, watchOnce } from "@vueuse/core";
import { useFocusTrap } from "@vueuse/integrations/useFocusTrap";
import SparSearchFlyout from "~/components/feature/SparSearchFlyout/SparSearchFlyout.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparSelect from "~/components/shared/SparSelect/SparSelect.vue";
import useI18n from "~/composables/i18n/useI18n";
import { useNavigationStore } from "~/stores/navigation.store";
import { useSearchStore } from "~/stores/search.store";

defineProps({
  ariaLabel: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const { $t } = useI18n();
const { getSuggestions, resetSuggestions, triggerSearchFromFlyout } = useSearchStore();
const { searchMode, searchModeSuggestions, showSearchOverlay, searchTerm, searchTermSuggestions } =
  storeToRefs(useSearchStore());
const { setMobileMenuActive } = useNavigationStore();

const search = ref();
const { activate, deactivate } = useFocusTrap(search, {
  immediate: false,
  setReturnFocus: ".search-field__submit-btn",
});

const options = $t("header.search.options")
  .split("|")
  .map((value, key) => {
    return {
      key,
      value,
    };
  });

const onSubmit = () => {
  triggerSearchFromFlyout();
};

const handleSearchInput = () => {
  // Show the flyout immediately after user action...
  if (!showSearchOverlay.value) showSearchOverlay.value = true;

  // ...but perform the search with a 500ms debounce
  debouncedHandleSuggestions();
};

const handleSuggestions = () => {
  resetSuggestions();

  if (searchTermSuggestions.value.length > 2) {
    getSuggestions(searchTermSuggestions.value);
  } else {
    searchTerm.value = "";
  }
};
const debouncedHandleSuggestions = useDebounceFn(() => {
  handleSuggestions();
}, 500);

watchOnce(searchMode, () => {
  // Search mode set externally (search page / deep link)
  searchModeSuggestions.value = searchMode.value;
});

watchOnce(searchTerm, () => {
  // Search term set externally (search page / deep link)
  searchTermSuggestions.value = searchTerm.value;
});

watch(
  () => showSearchOverlay.value,
  (value) => {
    // toggle focus trap for search overlay
    if (value) {
      activate();
    } else {
      deactivate();
    }
  },
);

onMounted(() => {
  // focus trap should be deactivated initially
  deactivate();
});
</script>

<style lang="scss">
@use "./SparSearchField.scss";
</style>
