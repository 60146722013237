<template>
  <header v-if="topHeader && mainHeader" class="header">
    <spar-meta-nav v-bind="mainHeader.metaNav" />
    <spar-top-header v-bind="topHeader" />
    <div v-if="showMobileSearch" class="header__search-mobile-wrapper">
      <spar-search-field
        class="header__search-mobile"
        v-bind="topHeader.search"
        @keydown.esc="closeMobileOverlays"
      />
    </div>
    <spar-main-header
      v-if="mainHeader.mainNavigation && mainHeader.metaNav"
      :main-navigation="mainHeader.mainNavigation"
      :meta-nav="mainHeader.metaNav"
    ></spar-main-header>
  </header>
</template>

<script lang="ts" setup>
import type { SparMainHeaderProps } from "~/components/sections/SparHeader/SparMainHeader/SparMainHeader.types";
import SparMainHeader from "~/components/sections/SparHeader/SparMainHeader/SparMainHeader.vue";
import SparMetaNav from "~/components/sections/SparHeader/SparMetaNav/SparMetaNav.vue";
import SparSearchField from "~/components/sections/SparHeader/SparSearchField/SparSearchField.vue";
import type { SparTopHeaderProps } from "~/components/sections/SparHeader/SparTopHeader/SparTopHeader.types";
import SparTopHeader from "~/components/sections/SparHeader/SparTopHeader/SparTopHeader.vue";
import { useNavigationStore } from "~/stores/navigation.store";
import { useSearchStore } from "~/stores/search.store";
import type { SparHeaderProps } from "./SparHeader.types";

const { isMobile } = useScreenInfo();
const { setMobileMenuActive } = useNavigationStore();
const { showMobileSearch, showSearchOverlay } = storeToRefs(useSearchStore());

defineProps({
  topHeader: {
    type: Object as PropType<SparTopHeaderProps>,
    default: undefined,
  },
  mainHeader: {
    type: Object as PropType<SparMainHeaderProps>,
    default: undefined,
  },
  uid: {
    type: String,
    default: undefined,
  },
}) as SparHeaderProps;

defineOptions({
  inheritAttrs: false,
});

const closeMobileOverlays = () => {
  setMobileMenuActive(null, true);
  showSearchOverlay.value = false;
};

const updateShowMobileSearch = () => {
  if (process.client) {
    showMobileSearch.value = isMobile.value;
  }
};

watch(isMobile, () => {
  updateShowMobileSearch();
});

onMounted(() => {
  updateShowMobileSearch();
});
</script>

<style lang="scss">
@use "./SparHeader.scss";
</style>
